"use client";

import { useEffect } from "react";

import { retrieveUserAccess } from "~/app/_actions/user";
import { useStore } from "~/hooks/store";

export default function InitializeState() {
  const store = useStore();

  useEffect(() => {
    retrieveUserAccess().then((access) => {
      access.currentAccess && store.setAccess(access.currentAccess);
    });
  }, []);

  return null;
}
