"use client";

import { useEffect, useState } from "react";
import Link from "next/link";
import { LuRocket } from "react-icons/lu";

import { getSubscribed } from "~/app/_actions/subscription";

export default function UpgradeBtn() {
  const [showBtn, setShowBtn] = useState(false);

  useEffect(() => {
    getSubscribed().then((subscribed) => {
      setShowBtn(!subscribed);
    });
  }, []);

  return (
    showBtn && (
      <Link
        className="inline-flex items-center rounded-md bg-indigo-500 px-4 py-2 text-sm font-medium text-gray-50 shadow-sm transition-colors hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        href="/plans"
      >
        Upgrade
        <LuRocket className="ml-2 h-4 w-4" />
        {/* <span className="ml-2">80% Off</span> */}
      </Link>
    )
  );
}
