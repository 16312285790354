"use client";

// import { Fragment } from "react";
// import { Disclosure, Menu, Transition } from "@headlessui/react";
import path from "path";
import Image from "next/image";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import circlesLogo from "@public/static/circles-logo.png";
import { useTranslations } from "next-intl";
import {
  LuBell,
  LuBuilding2,
  LuGlasses,
  LuMenu,
  LuSettings2,
} from "react-icons/lu";

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@acme/ui";
import { cn } from "@acme/utils";

import UpgradeBtn from "~/features/sub/upgrade-btn";
import { useStore } from "~/hooks/store";
import { DropdownAvatar } from "./dropdown-avatar";
import MainMenu, { type Navigation } from "./main-menu";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type NavProps = {
  userName: string;
  avatarUrl?: string;
};

export default function Nav({ userName, avatarUrl }: NavProps) {
  const pathname = usePathname();
  const t = useTranslations("nav");

  const access = useStore((state) => state.currentAccess);

  const navigation: Navigation = [
    {
      name: t("patients"),
      href: "/",
      icon: <LuGlasses />,
    },

    // { name: "Projects", href: "/x"},
    // { name: "Calendar", href: "/y" },
    { name: t("settings"), href: "/settings", icon: <LuSettings2 /> },
  ];

  return (
    <div className="border-gray-200 bg-white dark:bg-gray-900">
      <div className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between p-4">
        <Link href="/" className="flex items-center">
          <Image
            width={40}
            height={40}
            className="h-8 w-auto"
            src={circlesLogo}
            alt="Logo"
          />
          <span className="ml-2 hidden self-center whitespace-nowrap text-2xl font-semibold dark:text-white lg:block">
            OptiGrid
          </span>
        </Link>
        <div className="flex items-center space-x-4 md:order-2">
          <button
            type="button"
            className={cn(
              "hidden", /// Not implemented yet
              "rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800",
            )}
          >
            <span className="sr-only">View notifications</span>
            <LuBell className="h-6 w-6" aria-hidden="true" />
          </button>

          <UpgradeBtn />

          {/* Profile dropdown */}

          <DropdownAvatar
            userName={userName}
            avatarUrl={avatarUrl}
            access={access}
          />
          {/* <!-- Dropdown menu --> */}

          {navigation.length > 0 && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  type="button"
                  variant="secondary"
                  className="rounded-full md:hidden"
                >
                  <LuMenu className="h-6 w-6" aria-hidden="true" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuLabel>OptiGrid Menu</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                  {navigation.map((i) => (
                    <DropdownMenuItem key={i.name} asChild>
                      <Link href={i.href} className="flex items-center">
                        {!i.icon ? null : (
                          <span className="mr-2">{i.icon}</span>
                        )}
                        <span>{i.name}</span>
                      </Link>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>

        <div
          className="hidden w-full items-center justify-between md:order-1 md:flex md:w-auto"
          id="navbar-user"
        >
          <MainMenu
            navItems={navigation.map((i) => ({
              ...i,
              selected:
                i.href === pathname || pathname.startsWith(i.href + "/"),
            }))}
          />
        </div>
      </div>
    </div>
  );
}
