import Link from "next/link";

import { cn } from "@acme/utils";

export type Navigation = {
  name: string;
  href: string;
  selected?: boolean;
  icon?: React.ReactNode;
}[];

export default function MainMenu({
  className,
  navItems,
  ...props
}: React.HTMLAttributes<HTMLElement> & {
  navItems: Navigation;
}) {
  return (
    <nav
      className={cn("flex items-center space-x-4 lg:space-x-6", className)}
      {...props}
    >
      {navItems.map((item) => (
        <Link
          key={item.href}
          href={item.href}
          className={cn(
            "text-sm font-medium transition-colors hover:text-primary",
            item.selected ? "text-primary" : "text-muted-foreground",
          )}
        >
          <div className="flex items-center justify-center">
            {!item.icon ? null : <span className="mr-2">{item.icon}</span>}
            <span> {item.name}</span>
          </div>
        </Link>
      ))}
    </nav>
  );
}
